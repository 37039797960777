<template>
  <div>
    <h1>Financiën</h1>

    <AdminButton :isAdmin="isAdmin" :isSuperAdmin="isSuperAdmin" />

    <router-view :me="me" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import AdminButton from '../components/AdminButton.vue';

export default defineComponent({
  components: { AdminButton },

  props: {
    isAdmin: Boolean,
    isSuperAdmin: Boolean,
    me: Object,
  },

  name: 'Finance',
});
</script>
